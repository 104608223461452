
  export const style = {
    api: {
      apiProduction: "https://noz.prd.appnoz.com.br/",
      newApiProduction: "null",
      samlUrl: null,
      nameProfile: "NOZ",
      hostHeader: "noz",
    },

    icons: {
      loginIcon: "https://timcoo-geral.s3.amazonaws.com/Logos/noz.png",
      secondaryLogonLogo: "null",
      menuIcon: localStorage.getItem("menuIcon") || "https://timcoo-geral.s3.amazonaws.com/Logos/secondary_logo_noz.png",
      browserLogo: "https://timcoo-geral.s3.amazonaws.com/Logos/browser_logo_noz.png",
    },

    shareAttributes: {
      shareImage: "https://timcoo-geral.s3.amazonaws.com/Logos/noz.png",
      shareTitle: "Noz - Plataforma de comunicação",
      shareDescription: "O NOZ é um aplicativo de comunicação personalizado, onde é possível criar conteúdos de acordo com a segmentação do seu público de maneira gamificada.",
    },

    names: {
      nameApp: localStorage.getItem("nameApp") || "NOZ",
      nameScore: "Nozes",
      nameStore: "Loja",
      nameAcademy: localStorage.getItem("nameAcademy") || "Cursos",
      nameTeam: localStorage.getItem("nameTeam") || "Time",
      nameCalendar: localStorage.getItem("nameCalendar") || "Calendário",
      nameTools: localStorage.getItem("nameTools") || "Ferramentas",
    },

    colors: {
      primaryColor: localStorage.getItem("primaryColor") || "#2E63FB",
      secondaryColor: localStorage.getItem("secondaryColor") || "#2E63FB",
      tertiaryColor: "",
    },

    variables: {
      gamificationEnabled: true,
      child_profiles: [],
      hasPrivacyPolicies: false,
      externalUse: false,
      customClient: false,
      customMenuItem: false,
      autoRegister: false,
    },
  };
