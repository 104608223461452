export const defaultFirebase = {
  teamName: "timcoo",
  firebaseConfig: {
    apiKey: "AIzaSyCc7x7I8ZqMkwTEbkgHTr-o8VkgLUklsrQ",
    authDomain: "timcoo-cf0dc.firebaseapp.com",
    databaseURL: "https://timcoo-cf0dc.firebaseio.com",
    projectId: "timcoo-cf0dc",
    storageBucket: "timcoo-cf0dc.appspot.com",
    messagingSenderId: "848141043911",
    appId: "1:848141043911:web:d426fb3da5910f808fd6e2",
    measurementId: "G-4RJGP64P2L",
  },
};

export const teamFirebase = [
  {
    teamName: "grupopardini",
    firebaseConfig: {
      appId: "1:848141043911:web:3795348f9e2b64718fd6e2",
      measurementId: "G-VXP574HLS2",
      gtagId: "G-K3YH7P1KZT",
    },
  },
  {
    teamName: "ECOAR",
    firebaseConfig: {
      appId: "1:848141043911:web:d426fb3da5910f808fd6e2",
      measurementId: "G-4RJGP64P2L",
      gtagId: "G-3YJBHJ0NEM",
    },
  },
  {
    teamName: "CENTRO DE MEDICINA",
    firebaseConfig: {
      appId: "1:848141043911:web:056b4a53f9e656f78fd6e2",
      measurementId: "G-4TX0D8HQ2E",
      gtagId: "G-5RSQ1SGD2X",
    },
  },
  {
    teamName: "SOLUÇÃO",
    firebaseConfig: {
      appId: "1:848141043911:web:c6582605ab5b79488fd6e2",
      measurementId: "G-HH2TD6MP6Q",
      gtagId: "G-5G1KW9HTZ7",
    },
  },
  {
    teamName: "LABCLASS",
    firebaseConfig: {
      appId: "1:848141043911:web:1b44d632effd86f38fd6e2",
      measurementId: "G-85T4R9B3JV",
      gtagId: "G-J0EFRL5LLK",
    },
  },
  {
    teamName: "FERAs",
    firebaseConfig: {
      appId: "1:848141043911:web:38e7b491e122af5c8fd6e2",
      measurementId: "G-FFT2HKVMCT",
      gtagId: "G-N168BLH1KY",
    },
  },
  {
    teamName: "Hubsocial",
    firebaseConfig: {
      appId: "1:848141043911:web:ca0499d48ad80cec8fd6e2",
      measurementId: "G-YKTRSRF1FT",
      gtagId: "G-M6YPT20JXX",
    },
  },
  {
    teamName: "MRN",
    firebaseConfig: {
      appId: "1:848141043911:web:fde43891fb626d428fd6e2",
      measurementId: "G-PS1RC1VL51",
      gtagId: "G-K3K3Q7SSLK",
    },
  },
  {
    teamName: "Demo",
    firebaseConfig: {
      appId: "1:848141043911:web:8b323268bcb931b08fd6e2",
      measurementId: "G-2281JQHEG2",
      gtagId: "G-JJ2QXRLMYR",
    },
  },
  {
    teamName: "The Bakery Brasil",
    firebaseConfig: {
      appId: "1:848141043911:web:e747ec35ddd4a6a88fd6e2",
      measurementId: "G-BMZK1F5VYH",
      gtagId: "G-NLMF5Z2575",
    },
  },
  {
    teamName: "Safers",
    firebaseConfig: {
      appId: "1:848141043911:web:349a810c9bef8d208fd6e2",
      measurementId: "G-DDDZFL2Q1T",
      gtagId: "G-CSYGST2E3M",
    },
  },
  {
    teamName: "Noz",
    firebaseConfig: {
      appId: "1:848141043911:web:b03d9862c5e1e4718fd6e2",
      measurementId: "G-RVYPV1ZEM5",
      gtagId: "G-J0EFRL5LLK",
    },
  },
  {
    teamName: "Ets",
    firebaseConfig: {
      appId: "1:848141043911:web:1fa391165b3e49c18fd6e2",
      measurementId: "G-0CM1EH3MCX",
      gtagId: "G-KHYPE0FVX8",
    },
  },
  {
    teamName: "Time Cibra",
    firebaseConfig: {
      appId: "1:848141043911:web:be3576043efa506e8fd6e2",
      measurementId: "G-GN9Z233CVC",
      gtagId: "G-J0EFRL5LLK",
    },
  },
  {
    teamName: "Bosque",
    firebaseConfig: {
      appId: "1:848141043911:web:eb45977ea3f668258fd6e2",
      measurementId: "G-JK6EGP8R55",
      gtagId: "G-J0EFRL5LLK",
    },
  },
  {
    teamName: "Ligga",
    firebaseConfig: {
      appId: "1:848141043911:web:e64f0ab15dcacda48fd6e2",
      measurementId: "G-NJ7XVNPYX8",
      gtagId: "G-J0EFRL5LLK",
    },
  },
  {
    teamName: "sample",
    firebaseConfig: {
      apiKey: "AIzaSyCxu0cTFyiYs1AxVh1V5o-tolonlOcat5Q",
      authDomain: "sample-homolog.firebaseapp.com",
      databaseURL: "https://sample-homolog.firebaseio.com",
      projectId: "sample-homolog",
      storageBucket: "sample-homolog.appspot.com",
      messagingSenderId: "516245843351",
      appId: "1:516245843351:web:921a8fb505f329587268d2",
      measurementId: "G-52SWDY371V",
      gtagId: "G-YWL6Z9S94N",
    },
  },
  {
    teamName: "Ioasys",
    firebaseConfig: {
      apiKey: "AIzaSyD3kBS6hKoKtdEYD1YFEp5QnYLZj5AJth4",
      authDomain: "ioasys-team.firebaseapp.com",
      databaseURL: "https://ioasys-team.firebaseio.com",
      projectId: "ioasys-team",
      storageBucket: "ioasys-team.appspot.com",
      messagingSenderId: "983551189676",
      appId: "1:983551189676:web:ff4ccc1eb7d63cc3fd3509",
      measurementId: "G-FP04Q6YPG3",
      gtagId: "G-V25XQ8NDQN",
    },
  },
  {
    teamName: "Pintou Parceria Suvinil",
    firebaseConfig: {
      apiKey: "AIzaSyBwqBhJ3cfb1scrczWcIsjvxLrpJV6wBOc",
      authDomain: "pintouparceriasuvinil.firebaseapp.com",
      databaseURL: "https://pintouparceriasuvinil-default-rtdb.firebaseio.com",
      projectId: "pintouparceriasuvinil",
      storageBucket: "pintouparceriasuvinil.appspot.com",
      messagingSenderId: "865861034682",
      appId: "1:865861034682:web:39f9c26826f4538c8c9883",
      measurementId: "G-Q1G4E7W02F",
      gtagId: "G-MHH31P7WZL",
    },
  },
  {
    teamName: "Youseed",
    firebaseConfig: {
      apiKey: "AIzaSyBJ8e9XPyseaFV-I9uTExyDOi21sX-E-tM",
      authDomain: "banco-semear.firebaseapp.com",
      databaseURL: "https://banco-semear.firebaseio.com",
      projectId: "banco-semear",
      storageBucket: "banco-semear.appspot.com",
      messagingSenderId: "890508377656",
      appId: "1:890508377656:web:be6f3e39c9166a51dc30cd",
      measurementId: "G-02QV13RWHT",
      gtagId: "G-NGWX3QYK0N",
    },
  },
  {
    teamName: "Big",
    firebaseConfig: {
      apiKey: "AIzaSyCdpAtBx4-_24MlBVmeE_xa2cdydCS_JqQ",
      authDomain: "big-timcoo.firebaseapp.com",
      databaseURL: "https://big-timcoo.firebaseio.com",
      projectId: "big-timcoo",
      storageBucket: "big-timcoo.appspot.com",
      messagingSenderId: "160488695932",
      appId: "1:160488695932:web:8a3e38018591cdc4a581f9",
      measurementId: "G-FKE4T5WXX3",
      gtagId: "G-YHMFWDKZ0D",
    },
  },
  {
    teamName: "Sou Smarttbotter",
    firebaseConfig: {
      apiKey: "AIzaSyCjeKy1le_qCGHh2XhnOBPhYZj-XveTF-w",
      authDomain: "smarttbot-noz.firebaseapp.com",
      projectId: "smarttbot-noz",
      storageBucket: "smarttbot-noz.appspot.com",
      messagingSenderId: "635892766612",
      appId: "1:635892766612:web:1c058860db46794217e181",
      measurementId: "G-V0K655P5R3",
      gtagId: "G-NGFZ6G4FV4",
    },
  },
  {
    teamName: "PetroRio",
    firebaseConfig: {
      apiKey: "AIzaSyAaQha_bI4q8f_rNOEwFkpXkTGynoSLEsg",
      authDomain: "petrorio-2b9d4.firebaseapp.com",
      projectId: "petrorio-2b9d4",
      storageBucket: "petrorio-2b9d4.appspot.com",
      messagingSenderId: "365216947240",
      appId: "1:365216947240:web:e3cdd18adeb41f8c8b291d",
      measurementId: "G-CR1SH9M204",
      gtagId: "G-QH6QT66NHV",
    },
  },
  {
    teamName: "Astro",
    firebaseConfig: {
      apiKey: "AIzaSyBef0IDx4okbUWofkKtLqIwAAbNhNzU1TQ",
      authDomain: "astro-bb24d.firebaseapp.com",
      projectId: "astro-bb24d",
      storageBucket: "astro-bb24d.appspot.com",
      messagingSenderId: "436355814307",
      appId: "1:436355814307:web:c0cb5ea66957e841c68016",
      measurementId: "G-JMQBPXP9WT",
      gtagId: "G-J0EFRL5LLK",
    },
  },
  {
    teamName: "LPA",
    firebaseConfig: {
      appId: "1:848141043911:web:b3bbce899b2db66d8fd6e2",
      measurementId: "G-GJ810ST4TD",
      gtagId: "G-K3YH7P1KZT",
    },
  },
  {
    teamName: "SomosMachado",
    firebaseConfig: {
      appId: "1:848141043911:web:a9772f04a97269228fd6e2",
      measurementId: "G-S4F58ZDRKZ",
      gtagId: "G-J0EFRL5LLK",
    },
  },
  {
    teamName: "Grupo Arizona",
    firebaseConfig: {
      appId: "1:160488695932:web:4bea47327374050fa581f9",
      measurementId: "G-PE5NX8DZTG",
      gtagId: "G-J0EFRL5LLK",
    },
  },
  {
    teamName: "Serquip mg",
    firebaseConfig: {
      apiKey: "AIzaSyCdpAtBx4-_24MlBVmeE_xa2cdydCS_JqQ",
      authDomain: "big-timcoo.firebaseapp.com",
      databaseURL: "https://big-timcoo.firebaseio.com",
      projectId: "big-timcoo",
      storageBucket: "big-timcoo.appspot.com",
      messagingSenderId: "160488695932",
      appId: "1:160488695932:web:e5d76d026903ad47a581f9",
      measurementId: "G-MXMH8S9MQL",
    },
  },
  {
    teamName: "Voke",
    firebaseConfig: {
      apiKey: "AIzaSyCdpAtBx4-_24MlBVmeE_xa2cdydCS_JqQ",
      authDomain: "big-timcoo.firebaseapp.com",
      databaseURL: "https://big-timcoo.firebaseio.com",
      projectId: "big-timcoo",
      storageBucket: "big-timcoo.appspot.com",
      messagingSenderId: "160488695932",
      appId: "1:160488695932:web:2042122e3ea407bba581f9",
      measurementId: "G-ZEN7L184QK",
    },
  },
  {
    teamName: "pelando",
    firebaseConfig: {
      apiKey: "AIzaSyCdpAtBx4-_24MlBVmeE_xa2cdydCS_JqQ",
      authDomain: "big-timcoo.firebaseapp.com",
      databaseURL: "https://big-timcoo.firebaseio.com",
      projectId: "big-timcoo",
      storageBucket: "big-timcoo.appspot.com",
      messagingSenderId: "160488695932",
      appId: "1:160488695932:web:2042122e3ea407bba581f9",
      measurementId: "G-SWWP8N6PDV",
    },
  },
  {
    teamName: "Vedacit",
    firebaseConfig: {
      apiKey: "AIzaSyCdpAtBx4-_24MlBVmeE_xa2cdydCS_JqQ",
      authDomain: "big-timcoo.firebaseapp.com",
      databaseURL: "https://big-timcoo.firebaseio.com",
      projectId: "big-timcoo",
      storageBucket: "big-timcoo.appspot.com",
      messagingSenderId: "160488695932",
      appId: "1:160488695932:web:2042122e3ea407bba581f9",
      measurementId: "G-G0BT33CPDW",
    },
  },
  {
    teamName: "Abraselinos",
    firebaseConfig: {
      apiKey: "AIzaSyCdpAtBx4-_24MlBVmeE_xa2cdydCS_JqQ",
      authDomain: "big-timcoo.firebaseapp.com",
      databaseURL: "https://big-timcoo.firebaseio.com",
      projectId: "big-timcoo",
      storageBucket: "big-timcoo.appspot.com",
      messagingSenderId: "160488695932",
      appId: "1:160488695932:web:2042122e3ea407bba581f9",
      measurementId: "G-H83LMBN1H0",
    },
  },
  {
    teamName: "Aterpa",
    firebaseConfig: {
      apiKey: "AIzaSyCdpAtBx4-_24MlBVmeE_xa2cdydCS_JqQ",
      authDomain: "big-timcoo.firebaseapp.com",
      databaseURL: "https://big-timcoo.firebaseio.com",
      projectId: "big-timcoo",
      storageBucket: "big-timcoo.appspot.com",
      messagingSenderId: "160488695932",
      appId: "1:160488695932:web:2042122e3ea407bba581f9",
      measurementId: "G-74D149C59P",
    },
  },
  {
    teamName: "Lwart",
    firebaseConfig: {
      apiKey: "AIzaSyC9MhSYt12reS7c7kXCt9cRouKFWHYdOJE",
      authDomain: "lwart-2ca37.firebaseapp.com",
      projectId: "lwart-2ca37",
      storageBucket: "lwart-2ca37.appspot.com",
      messagingSenderId: "839805739058",
      appId: "1:839805739058:web:55fbd68cb92a27e8d2dc2b",
      measurementId: "G-2T1HEX42QX",
    },
  },
  {
    teamName: "Cipa",
    firebaseConfig: {
      apiKey: "AIzaSyCdpAtBx4-_24MlBVmeE_xa2cdydCS_JqQ",
      authDomain: "big-timcoo.firebaseapp.com",
      databaseURL: "https://big-timcoo.firebaseio.com",
      projectId: "big-timcoo",
      storageBucket: "big-timcoo.appspot.com",
      messagingSenderId: "160488695932",
      appId: "1:160488695932:web:2042122e3ea407bba581f9",
      measurementId: "G-PSXBZY39NL",
    },
  },
  {
    teamName: "mestreconstrutor",
    firebaseConfig: {
      apiKey: "AIzaSyCdpAtBx4-_24MlBVmeE_xa2cdydCS_JqQ",
      authDomain: "big-timcoo.firebaseapp.com",
      databaseURL: "https://big-timcoo.firebaseio.com",
      projectId: "big-timcoo",
      storageBucket: "big-timcoo.appspot.com",
      messagingSenderId: "160488695932",
      appId: "1:160488695932:web:db16b4e41a70c056a581f9",
      measurementId: "G-QEN93MDSS5",
    },
  },
  {
    teamName: "Testzone",
    firebaseConfig: {
      apiKey: "AIzaSyDX_jVwpTWFN51_dI7NxvK3PMeZ9rHioXs",
      authDomain: "testzone-674e5.firebaseapp.com",
      projectId: "testzone-674e5",
      storageBucket: "testzone-674e5.appspot.com",
      messagingSenderId: "341052551299",
      appId: "1:341052551299:web:0e99d4134e15780c262bb6",
      measurementId: "G-2RSSH51MWG",
    },
  },
  {
    teamName: "Foton",
    firebaseConfig: {
      apiKey: "AIzaSyC_TRpeKcjV_ypER6larIJ1mWJiqbrVwQQ",
      authDomain: "fotonapp-82db6.firebaseapp.com",
      projectId: "fotonapp-82db6",
      storageBucket: "fotonapp-82db6.appspot.com",
      messagingSenderId: "37168860747",
      appId: "1:37168860747:web:75ec826867be22c0f507ce",
      measurementId: "G-MMGFFQTQT4",
    },
  },
  {
    teamName: "Bein",
    firebaseConfig: {
      apiKey: "AIzaSyCdpAtBx4-_24MlBVmeE_xa2cdydCS_JqQ",
      authDomain: "big-timcoo.firebaseapp.com",
      databaseURL: "https://big-timcoo.firebaseio.com",
      projectId: "big-timcoo",
      storageBucket: "big-timcoo.appspot.com",
      messagingSenderId: "160488695932",
      appId: "1:160488695932:web:1fd4a4f7f48920fba581f9",
      measurementId: "G-BVLBHN1D0X",
    },
  },
  {
    teamName: "Ecom",
    firebaseConfig: {
      apiKey: "AIzaSyAGP-9lC6G98dwwcNdNtrquskPBSDvs7jM",
      authDomain: "ecom-aa78c.firebaseapp.com",
      projectId: "ecom-aa78c",
      storageBucket: "ecom-aa78c.appspot.com",
      messagingSenderId: "336855987681",
      appId: "1:336855987681:web:33b5cba2ba2e771f4b8ea0",
      measurementId: "G-2VKFWSP4P8",
    },
  },
  {
    teamName: "ctverso",
    firebaseConfig: {
      apiKey: "AIzaSyCijupJ4J33k4PFwz_zIR-9ehF57jkA__A",
      authDomain: "ctverso-69237.firebaseapp.com",
      projectId: "ctverso-69237",
      storageBucket: "ctverso-69237.appspot.com",
      messagingSenderId: "692098112966",
      appId: "1:692098112966:web:b752feb5865affdc4ba6c7",
      measurementId: "G-Z9K5T8EJZF",
    },
  },
  {
    teamName: "VOEPASS Comunica",
    firebaseConfig: {
      apiKey: "AIzaSyCdpAtBx4-_24MlBVmeE_xa2cdydCS_JqQ",
      authDomain: "big-timcoo.firebaseapp.com",
      databaseURL: "https://big-timcoo.firebaseio.com",
      projectId: "big-timcoo",
      storageBucket: "big-timcoo.appspot.com",
      messagingSenderId: "160488695932",
      appId: "1:160488695932:web:e88dc36b27c4a1faa581f9",
      measurementId: "G-YK9FY15KML",
    },
  },
  {
    teamName: "Hackateno",
    firebaseConfig: {
      apiKey: "AIzaSyCgI7_1fBnAspJssnSglTIoW47z2tif3nI",
      authDomain: "hackateno-733dd.firebaseapp.com",
      projectId: "hackateno-733dd",
      storageBucket: "hackateno-733dd.appspot.com",
      messagingSenderId: "500695431864",
      appId: "1:500695431864:web:55bd1fa9a5b97804b2d24d",
      measurementId: "G-3050SN7Z3M",
    },
  },
  {
    teamName: "Proauto",
    firebaseConfig: {
      apiKey: "AIzaSyAKY1iiCBNA5HFA6rxvylbagShR1rsPfQ0",
      authDomain: "proauto-garagem.firebaseapp.com",
      projectId: "proauto-garagem",
      storageBucket: "proauto-garagem.appspot.com",
      messagingSenderId: "713382437666",
      appId: "1:713382437666:web:06733ac2536a1a60c23b7b",
      measurementId: "G-78NQ9L9B6F",
    },
  },
  {
    teamName: "clubecasa",
    firebaseConfig: {
      apiKey: "AIzaSyDFbkaY7QDlir5ZCGrYdA-H7KRDAPwHCac",
      authDomain: "clubecasa-cfc84.firebaseapp.com",
      projectId: "clubecasa-cfc84",
      storageBucket: "clubecasa-cfc84.appspot.com",
      messagingSenderId: "697824301847",
      appId: "1:697824301847:web:ef84d0effb7e7ee07c9d52",
      measurementId: "G-30MMZXHV6C",
    },
  },
  {
    teamName: "Acembra Sincep",
    firebaseConfig: {
      apiKey: "AIzaSyAVLDNZESXk_-aAZs05qwoCm0Q0APkcKCg",
      authDomain: "big-timcoo.firebaseapp.com",
      databaseURL: "https://big-timcoo.firebaseio.com",
      projectId: "big-timcoo",
      storageBucket: "big-timcoo.firebasestorage.app",
      messagingSenderId: "160488695932",
      appId: "1:160488695932:web:91de5a5263ed7e38a581f9",
      measurementId: "G-Z1YFESE60Q",
    },
  },
  {
    teamName: "Nacao Valquirias",
    firebaseConfig: {
      apiKey: "AIzaSyAVLDNZESXk_-aAZs05qwoCm0Q0APkcKCg",
      authDomain: "big-timcoo.firebaseapp.com",
      databaseURL: "https://big-timcoo.firebaseio.com",
      projectId: "big-timcoo",
      storageBucket: "big-timcoo.firebasestorage.app",
      messagingSenderId: "160488695932",
      appId: "1:160488695932:web:65134924723516c0a581f9",
      measurementId: "G-9JP5V9V2CP",
    },
  },
  {
    teamName: "solidianos",
    firebaseConfig: {
      apiKey: "AIzaSyDMiCU5qptDGbLrnMrjt0jvf8DnATBXd-E",
      authDomain: "solidianos.firebaseapp.com",
      projectId: "solidianos",
      storageBucket: "solidianos.firebasestorage.app",
      messagingSenderId: "137391956130",
      appId: "1:137391956130:web:f0fa91187df7c32cc2b7fb",
      measurementId: "G-JY1E7P0SLS",
    },
  },
];
