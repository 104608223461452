/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { Creators as AuthActions } from "store/ducks/auth";
import { style } from "style";
import Flex from "components/core/Grid/Flex";
import { PageTitle } from "components/core/Typography/Typography";
import {
  Input,
  InputMask,
  Textarea,
  Select,
  SwitchProfilePerfil,
} from "components/core/Form/Form";
import Button from "components/core/Button/Button";
import { history, validateCNPJ } from "helpers/sharedHelpers";
import moment from "moment";
import "moment/locale/pt-br";

import {
  ImageName,
  ButtonImage,
  ProfileImage,
  LabelButtonImage,
  LabelButtonRemove,
  ProfileHeader,
  ProfileForm,
  BoxProfile,
} from "./EditProfileStyle";

import * as S from "../SignUp/SignUpStyle";

const EditProfile = ({ updateDataUser }) => {
  const {
    isLoadingUpdate,
    user,
    personalQuestions: questions,
  } = useSelector((state) => state.user);

  const { listState, listCities } = useSelector((state) => state.auth);
  const intl = useIntl();
  const dispatch = useDispatch();
  const [imgPreview, setImgPreview] = useState("");
  const [fileName, setFileName] = useState("");
  const [editPermission, setEditPermission] = useState({
    name: false,
    email: false,
    phone: false,
    state: false,
    city: false,
    address_number: false,
  });

  const [showCities, setShowCities] = useState(false);
  const [listCitiesState, setListCitiesState] = useState(listCities);
  const hasAddress = style.variables.autoRegister;
  const hasCustomInputDisabled = ["somosmachado", "sample"].includes(
    style.names.nameApp,
  );
  const isMc = style.names.nameApp === "mestreconstrutor";
  const isSuvinil = style.names.nameApp === "Pintou Parceria Suvinil";
  const isEcom = style.names.nameApp === "Ecom";
  const isAcembra = style.names.nameApp === "Acembra Sincep";
  const idUser = localStorage.getItem("idUser");

  const listSexGender = [
    {
      text: "Mulher cis",
      id: 1,
      value: "cis_woman",
      title: "Nasceu com orgão genital feminino e se identifica como mulher",
    },
    {
      text: "Mulher trans",
      id: 2,
      value: "trans_woman",
      title: "Nasceu com órgão genital masculino e se identifica como mulher",
    },
    {
      text: "Homem cis",
      id: 3,
      value: "cis_man",
      title: "Nasceu com órgão genital masculino e se identifica como homem",
    },
    {
      text: "Homem trans",
      id: 4,
      value: "trans_man",
      title: "Nasceu com órgão genital feminino e se identifica como homem",
    },
    {
      text: "Não binário",
      id: 5,
      value: "not_binary",
      title: "Não se identifica especificamente como ou homem ou como mulher",
    },
    {
      text: "Prefere não se identificar",
      id: 6,
      value: "prefers_not_to_identify",
    },
    {
      text: "Outro",
      id: 7,
      value: "other",
    },
  ];

  const listSexGenderReduced = [
    {
      text: "Feminino",
      id: 8,
      value: "feminine",
      title: "Feminino",
    },
    {
      text: "Masculino",
      id: 9,
      value: "masculine",
      title: "Masculino",
    },
    {
      text: "Prefere não se identificar",
      id: 6,
      value: "prefers_not_to_identify",
    },
    {
      text: "Outro",
      id: 7,
      value: "other",
    },
  ];

  const updateSexGender = (genderUser) => {
    const Genders = {
      "Mulher cis": "cis_woman",
      "Mulher trans": "trans_woman",
      "Homem cis": "cis_man",
      "Homem trans": "trans_man",
      "Não binário": "not_binary",
      "Prefere não se identificar": "prefers_not_to_identify",
      Feminino: "feminine",
      Masculino: "masculine",
      default: "other",
    };

    return Genders[genderUser] || Genders.default;
  };

  const [form, setForm] = useState({});

  useEffect(() => {
    if (Object.keys(user).length) {
      setForm({
        image: user.image.url,
        name: user.name,
        email: user.email,
        sex: updateSexGender(user.sex),
        custom_gender:
          updateSexGender(user.sex) === "other" && user.sex !== "null"
            ? user.sex
            : "",
        show_gender: user.show_gender,
        datebirthday: moment(user.datebirthday).utc().format("DD-MM-YYYY"),
        bio: user.bio || "",
        interests: user.interests || "",
        skills: user.skills || "",
        contacts: [...user.contacts],
        show_datebirthday: user.show_datebirthday,
        show_email: user.show_email,
        show_phone: user.show_phone,
        zip_code: user.address !== null ? user.address.zip_code : "",
        street:
          user.address !== null && user.address.street !== "null"
            ? user.address.street
            : "",
        neighborhood:
          user.address !== null && user.address.neighborhood !== "null"
            ? user.address.neighborhood
            : "",
        state_id: user.state !== null ? user.state.id : "",
        state: user.state !== null ? user.state.uf : "",
        city_id: user.city !== null ? user.city.id : "",
        city: user.city !== null ? user.city.name : "",
        number: user.address !== null ? user.address.number : "",
        complement:
          user.address !== null &&
          user.address.complement !== null &&
          user.address.complement !== "null"
            ? user.address.complement
            : "",
        cnpj: user.cnpj,
        // activity_info: user.user_activity_info,
      });
    }
  }, [user]);

  const previewImg = (image) => {
    setFileName(image.target.files[0].name);
    setImgPreview(URL.createObjectURL(image.target.files[0]));

    setForm({ ...form, image: image.target.files[0] });
  };
  useEffect(() => {
    setEditPermission({
      name: form.name !== "",
      email: form.email !== "",
      city: !hasAddress || form.city !== "",
      state: !hasAddress || form.state_id !== "",
      address_number: !hasAddress || form.number !== "",
    });
  }, [
    form.name,
    form.email,
    form.city,
    form.state_id,
    form.number,
    hasAddress,
  ]);

  const changeContacts = (kind, newValue) => {
    const contact = form.contacts.find((contact) => contact.kind === kind);

    if (contact === undefined) {
      setForm({
        ...form,
        contacts: [
          ...form.contacts,
          {
            kind,
            contact: newValue,
          },
        ],
      });
    } else {
      setForm({
        ...form,
        contacts: form.contacts?.map((contact) =>
          contact.kind === kind
            ? {
                ...contact,
                contact: newValue,
              }
            : contact,
        ),
      });
    }
  };

  const RemoveProfileImg = () => {
    setFileName("");
    setImgPreview("");
    setForm({ ...form, image: "" });
  };

  const updateUser = (e) => {
    e.preventDefault();

    form.address = {
      street: form.street,
      number: form.number,
      complement: form.complement,
      zip_code: form.zip_code,
      neighborhood: form.neighborhood,
      uf: form.state,
      city: form.city,
    };
    delete form.street;
    delete form.number;
    delete form.complement;
    delete form.zip_code;
    delete form.neighborhood;
    delete form.state;
    delete form.city;

    updateDataUser(form);
    history.push(`/perfil/${idUser}`);
  };

  const handleCep = (value) => {
    setForm({ ...form, zip_code: value });

    if (value.length === 9) {
      fetch(`https://viacep.com.br/ws/${value.replace("-", "")}/json/`).then(
        (response) => {
          response.json().then((data) => {
            const { localidade, logradouro, cep, uf, bairro } = data;
            const findUf = listState.find((item) => item.uf === uf);
            setForm({
              ...form,
              city: localidade,
              street: logradouro,
              zip_code: cep,
              neighborhood: bairro,
              state_id: findUf.id,
              state: uf,
            });
          });
        },
      );
    }
  };

  const dispatchGetStateList = useCallback(
    () => dispatch(AuthActions.getStateList()),
    [dispatch],
  );
  useEffect(() => {
    dispatchGetStateList();
  }, [dispatchGetStateList]);

  const dispatchGetCitiesList = useCallback(
    (CitiesId) => dispatch(AuthActions.getCitiesList({ id: CitiesId })),
    [dispatch],
  );

  useEffect(() => {
    Number.isInteger(form.state_id) && dispatchGetCitiesList(form.state_id);
  }, [form.state_id]);

  const handleSendCities = (valueId) => {
    dispatchGetCitiesList(valueId);
    let new_uf;
    listState.map((state) => {
      if (state.id == valueId) new_uf = state.uf;
    });
    setForm({
      ...form,
      state_id: valueId,
      state: new_uf,
      city: "",
      city_id: "",
      street: "",
      zip_code: "",
      number: "",
      complement: "",
      neighborhood: "",
    });
    setShowCities(false);
  };

  const handleSearchCities = (nameCity) => {
    if (nameCity.length > 0) {
      const filterCities = listCities?.filter(
        (cities) =>
          cities.text?.toLowerCase().indexOf(nameCity.toLowerCase().trim()) >
            -1 && cities,
      );
      setShowCities(true);
      setListCitiesState(filterCities);
      if (
        filterCities.length === 1 &&
        filterCities[0].text === nameCity.trim()
      ) {
        setForm({ ...form, city_id: filterCities[0].id });
        setShowCities(false);
      }
    }
    setForm({ ...form, city: nameCity });
  };

  const handleClick = (cities) => {
    setShowCities(false);
    setForm({
      ...form,
      city: cities.text,
      city_id: `${cities.id}`,
    });
  };

  useEffect(() => {
    setListCitiesState(listCities);

    const findCity = listCities.find((item) => item.text === form.city);
    if (findCity !== undefined)
      setForm({
        ...form,
        city_id: findCity.id,
      });
  }, [listCities]);

  return (
    <BoxProfile>
      <ProfileForm onSubmit={(e) => updateUser(e)}>
        <PageTitle title={intl.messages["profile.textPage.myProfile"]}>
          <Flex alignItems="center" spaceChildren={3}>
            <ProfileHeader>
              <Button
                name={intl.messages["general.buttons.back"]}
                variant={["transparent"]}
                action={() => history.push(`/perfil/${idUser}`)}
              />

              {Object.values(editPermission).indexOf(false) === -1 && (
                <Button
                  type="submit"
                  name={intl.messages["general.buttons.save"]}
                  isLoading={isLoadingUpdate}
                  disabled={isLoadingUpdate}
                />
              )}
            </ProfileHeader>
          </Flex>
        </PageTitle>

        <Flex
          p={3}
          flexDirection="column"
          spaceChildren={3}
          maxWidth="500px"
          height="100%"
        >
          <Flex mb={5} spaceChildren={3}>
            <Flex flexDirection="column">
              <ProfileImage image={imgPreview || form.image}>
                <ButtonImage
                  id="upload"
                  type="file"
                  accept="image/*"
                  onChange={(e) => previewImg(e)}
                />
                <LabelButtonImage htmlFor="upload">
                  {form.image || imgPreview
                    ? intl.messages["general.buttons.change"]
                    : intl.messages["general.buttons.add"]}
                </LabelButtonImage>
              </ProfileImage>
              {(form.image || imgPreview) && (
                <LabelButtonRemove onClick={RemoveProfileImg}>
                  {intl.messages["general.buttons.remove"]}
                </LabelButtonRemove>
              )}
            </Flex>
            {imgPreview && (
              <ImageName>
                Foto de avatar
                <h3 image={!!imgPreview} id="nameImg">
                  {imgPreview
                    ? fileName
                    : intl.messages["profile.textPage.addImage"]}
                </h3>
              </ImageName>
            )}
          </Flex>
          <Input
            disabled={hasCustomInputDisabled}
            inputCustomDisabled={hasCustomInputDisabled}
            name="name"
            value={form.name}
            error={
              form.name === "" && intl.messages["general.buttons.inputRequired"]
            }
            placeholder={`${intl.messages["profile.textPage.typeYourName"]}`}
            label={`${intl.messages["profile.name"]}*`}
            onChange={(e) => {
              setForm({ ...form, name: e.target.value });
            }}
          />
          {questions[0] !== undefined && (
            <Textarea
              rows={6}
              name="bio"
              value={form.bio}
              placeholder={
                questions[0].placeholder === null
                  ? ""
                  : `${questions[0].placeholder}`
              }
              label={`${questions[0].title}`}
              onChange={(e) => setForm({ ...form, bio: e.target.value })}
              isPersonalQuestion
            />
          )}

          <InputMask
            mask="99/99/9999"
            maskChar={null}
            type="text"
            name="datebirthday"
            value={form.datebirthday}
            placeholder={`${intl.messages["profile.textPage.typeYourBirthday"]}`}
            label={intl.messages["profile.datebirthday"]}
            onChange={(e) => {
              setForm({ ...form, datebirthday: e.target.value });
            }}
          />
          <SwitchProfilePerfil
            title="Exibir data de aniversário:"
            active={form.show_datebirthday}
            onChange={() =>
              setForm({ ...form, show_datebirthday: !form.show_datebirthday })
            }
          />
          <Input
            name="emailContact"
            value={
              form.contacts?.find((contact) => contact.kind === "email")
                ?.contact || ""
            }
            placeholder={`${intl.messages["profile.textPage.typeYourEmail"]}`}
            label={
              style.names.nameApp === "Pintou Parceria Suvinil"
                ? "E-MAIL"
                : "E-MAIL"
            }
            onChange={(e) => changeContacts("email", e.target.value)}
            defaultValue="@"
          />
          <SwitchProfilePerfil
            title="Exibir e-mail:"
            active={form.show_email}
            onChange={() => setForm({ ...form, show_email: !form.show_email })}
          />
          {style.variables.gamificationEnabled && (
            <>
              <Select
                value={form.sex}
                options={
                  isMc || isSuvinil || isEcom || isAcembra
                    ? listSexGenderReduced
                    : listSexGender
                }
                label={
                  isMc || isSuvinil || isEcom || isAcembra
                    ? intl.messages["profile.sex"]
                    : intl.messages["profile.gender"]
                }
                onChange={(e) => setForm({ ...form, sex: e.target.value })}
              />
              {form.sex === "other" && (
                <Input
                  name="name"
                  value={form.custom_gender}
                  label={
                    isMc || isSuvinil || isEcom || isAcembra
                      ? intl.messages["profile.otherSex"]
                      : intl.messages["profile.otherGender"]
                  }
                  placeholder={
                    isMc || isSuvinil || isEcom || isAcembra
                      ? intl.messages["profile.textPage.typeYourSex"]
                      : intl.messages["profile.textPage.typeYourGender"]
                  }
                  onChange={(e) =>
                    setForm({ ...form, custom_gender: e.target.value })
                  }
                />
              )}
              <SwitchProfilePerfil
                title={`Exibir ${
                  isMc || isSuvinil || isEcom || isAcembra ? "sexo" : "gênero"
                }:`}
                active={form.show_gender}
                onChange={() =>
                  setForm({ ...form, show_gender: !form.show_gender })
                }
              />
            </>
          )}
          <InputMask
            mask="(99) 9 9999-9999"
            maskChar={null}
            type="text"
            name="phone"
            value={
              form.contacts?.find((contact) => contact.kind === "phone")
                ?.contact || ""
            }
            placeholder={`${intl.messages["profile.textPage.typePhone"]}`}
            label={`${intl.messages["profile.phone"]}`}
            onChange={(e) => changeContacts("phone", e.target.value)}
          />
          <SwitchProfilePerfil
            title="Exibir telefone:"
            active={form.show_phone}
            onChange={() => setForm({ ...form, show_phone: !form.show_phone })}
          />
          {style.variables.child_profiles.length > 0 && (
            <InputMask
              mask="(99) 9999-9999"
              maskChar={null}
              type="text"
              name="extension_line"
              value={
                form.contacts?.find(
                  (contact) => contact.kind === "extension_line",
                )?.contact || ""
              }
              placeholder={`${intl.messages["profile.textPage.typePhoneRamal"]}`}
              label={`${intl.messages["profile.phoneRamal"]}`}
              onChange={(e) => changeContacts("extension_line", e.target.value)}
            />
          )}
          {questions[1] !== undefined && (
            <Textarea
              name="interests"
              value={form.interests}
              placeholder={
                questions[1].placeholder === null
                  ? ""
                  : `${questions[1].placeholder}`
              }
              label={`${questions[1].title}`}
              onChange={(e) => setForm({ ...form, interests: e.target.value })}
              isPersonalQuestion
            />
          )}
          {questions[2] !== undefined && (
            <Textarea
              name="skills"
              value={form.skills}
              placeholder={
                questions[2].placeholder === null
                  ? ""
                  : `${questions[2].placeholder}`
              }
              label={`${questions[2].title}`}
              onChange={(e) => setForm({ ...form, skills: e.target.value })}
              isPersonalQuestion
            />
          )}
          {(style.names.nameApp === "Pintou Parceria Suvinil" ||
            style.names.nameApp === "sample") &&
            user.auto_declared === "clerk" && (
              <InputMask
                mask="99.999.999/9999-99"
                maskChar={null}
                type="text"
                name="cnpj"
                error={
                  !validateCNPJ(form.cnpj ?? "") &&
                  intl.messages["general.buttons.inputCNPJInvalid"]
                }
                placeholder={intl.messages["profile.textPage.typeYourCNPJ"]}
                label={`${intl.messages["profile.cnpj"]}*`}
                onChange={(e) => setForm({ ...form, cnpj: e.target.value })}
                value={form.cnpj}
              />
            )}

          {/* {style.names.nameApp === "astro" &&
            user.user_activity === "Sou Parceiro Ecom" && (
              <InputMask
                mask="99.999.999/9999-99"
                maskChar={null}
                type="text"
                name="cnpj"
                error={
                  !validateCNPJ(form.activity_info ?? "") &&
                  intl.messages["general.buttons.inputCNPJInvalid"]
                }
                placeholder={intl.messages["profile.textPage.typeYourCNPJ"]}
                label={`${intl.messages["profile.cnpj"]}*`}
                onChange={(e) =>
                  setForm({ ...form, activity_info: e.target.value })
                }
                value={form.activity_info}
              />
            )} */}

          {style.variables.gamificationEnabled && (
            <>
              <Input
                iconSrc="facebook"
                name="facebook"
                prefix="facebook.com/"
                value={
                  form.contacts?.find((contact) => contact.kind === "facebook")
                    ?.contact || ""
                }
                placeholder={`${intl.messages["profile.textPage.typeFacebook"]}`}
                label={intl.messages["profile.facebook"]}
                onChange={(e) => changeContacts("facebook", e.target.value)}
              />
              <Input
                iconSrc="instagram"
                name="instagram"
                prefix="@"
                value={
                  form.contacts?.find((contact) => contact.kind === "instagram")
                    ?.contact || ""
                }
                placeholder={`${intl.messages["profile.textPage.typeInstagram"]}`}
                label={intl.messages["profile.instagram"]}
                onChange={(e) => changeContacts("instagram", e.target.value)}
              />
              <Input
                iconSrc="twitter"
                name="twitter"
                prefix="@"
                value={
                  form.contacts?.find((contact) => contact.kind === "twitter")
                    ?.contact
                }
                placeholder={`${intl.messages["profile.textPage.typeTwitter"]}`}
                label={intl.messages["profile.twitter"]}
                onChange={(e) => changeContacts("twitter", e.target.value)}
              />
            </>
          )}
          <Input
            iconSrc="linkedin"
            name="linkedin"
            prefix="linkedin.com/in/"
            value={
              form.contacts?.find((contact) => contact.kind === "linkedin")
                ?.contact || ""
            }
            placeholder={`${intl.messages["profile.textPage.typeLinkedin"]}`}
            label={intl.messages["profile.linkedin"]}
            onChange={(e) => changeContacts("linkedin", e.target.value)}
          />

          {hasAddress && (
            <>
              <span>Endereço</span>

              <InputMask
                mask="99999-999"
                maskChar={null}
                type="text"
                name="zip_code"
                placeholder={intl.messages["profile.textPage.typeYourCEP"]}
                label={`${intl.messages["profile.cep"]}`}
                onChange={(e) => handleCep(e.target.value)}
                value={form.zip_code}
              />

              <Input
                type="text"
                name="street"
                placeholder={intl.messages["profile.textPage.typeYourAddress"]}
                label={`${intl.messages["profile.address"]}`}
                onChange={(e) => setForm({ ...form, street: e.target.value })}
                value={form.street}
              />
              <Flex>
                <Flex mr={9}>
                  <Input
                    type="number"
                    name="number"
                    error={
                      form.number === "" &&
                      intl.messages["general.buttons.inputRequired"]
                    }
                    placeholder={
                      intl.messages["profile.textPage.typerYourNumber"]
                    }
                    label={`${intl.messages["profile.number"]}*`}
                    onChange={(e) =>
                      setForm({ ...form, number: e.target.value })
                    }
                    value={form.number}
                  />
                </Flex>
                <Flex ml={9}>
                  <Input
                    type="text"
                    name="complement"
                    placeholder={
                      intl.messages["profile.textPage.typeYourComplement"]
                    }
                    label={`${intl.messages["profile.complement"]}`}
                    onChange={(e) =>
                      setForm({ ...form, complement: e.target.value })
                    }
                    value={form.complement}
                  />
                </Flex>
              </Flex>
              <Input
                type="text"
                name="neighborhood"
                placeholder={
                  intl.messages["profile.textPage.typeYourNeighborhood"]
                }
                label={`${intl.messages["profile.neighborhood"]}`}
                onChange={(e) =>
                  setForm({ ...form, neighborhood: e.target.value })
                }
                value={form.neighborhood}
              />
              <Select
                value={form.state_id}
                options={listState}
                label={`${intl.messages["profile.state"]}*`}
                onChange={(e) => handleSendCities(e.target.value)}
                error={
                  form.state_id === "" &&
                  intl.messages["general.buttons.inputRequired"]
                }
              />
              <S.ContentSelectCity>
                <S.ListCities cityValidation={showCities && form.city}>
                  {showCities &&
                    listCitiesState.map((cities) => (
                      <S.ContentCities
                        key={cities.id}
                        onClick={() => handleClick(cities)}
                      >
                        {cities.text}
                      </S.ContentCities>
                    ))}
                </S.ListCities>
                <Input
                  type="text"
                  name="city"
                  placeholder={intl.messages["login.textPage.typeYourCity"]}
                  label={`${intl.messages["profile.city"]}*`}
                  onChange={(e) => handleSearchCities(e.target.value)}
                  value={form.city}
                  error={
                    form.city === "" &&
                    intl.messages["general.buttons.inputRequired"]
                  }
                  autocomplete="your_city"
                />
              </S.ContentSelectCity>
            </>
          )}
        </Flex>
      </ProfileForm>
    </BoxProfile>
  );
};

export default EditProfile;
